import http from '@/utils/http.js'

// 列表
export function productList(data) {
     return http({
          url: "/jasoboss/product/list",
          method: "post",
          data
     })
}

// 添加
export function productAdd(data) {
     return http({
          url: "/jasoboss/product/add",
          method: "post",
          data
     })
}
// 修改
export function productUpdate(data) {
     return http({
          url: "/jasoboss/product/update",
          method: "post",
          data
     })
}
// 删除
export function productDelete(data) {
     return http({
          url: "/jasoboss/product/delete",
          method: "post",
          data
     })
}
// 产品所有属性列表
export function productAttributeList(data) {
     return http({
          url: "/jasoboss/product/attribute/list",
          method: "post",
          data
     })
}
// 添加产品和属性的关联
export function productAttributeRelationAdd(data) {
     return http({
          url: "/jasoboss/product/attribute/relation/add",
          method: "post",
          data
     })
}
// 产品和属性的关联列表
export function productAttributeRelationList(data) {
     return http({
          url: "/jasoboss/product/attribute/relation/list",
          method: "post",
          data
     })
}
//修改产品和属性的关联列表
export function productAttributeRelationUpdate(data) {
     return http({
          url: "/jasoboss/product/attribute/relation/update",
          method: "post",
          data
     })
}